<template>
    <div id="carrieres" class="page-wrapper page-carrieres-details">
        <!-- SubHero -->
        <header class="carrieres-hero site-max-width" hero-transition-group :class="this.jobPost ? this.jobPost.secteurSlug : ''">
            <div class="container">
                <div class="content">
                    <h1 class="big-text text" data-inview="fadeInUp" data-delay="200">
                        {{ this.jobPost ? this.jobPost.title : '' }}
                    </h1>

                    <div data-inview="fadeInUp" data-delay="400">
                        <a :href="this.jobPost ? this.jobPost.lienPostulez : ''" class="link-text" target="_blank" rel="nooperner noreferrer">
                            <span>Postulez</span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="689.071 5131.187 124.43 124.43"><path d="M723.663 5161.133v6.712h48.361l-55.417 55.416 4.733 4.733 55.502-55.503v48.533h6.713v-59.891h-59.892Z" fill="#1e1e1e" fill-rule="evenodd" data-name="Path 30"/></svg>
                        </a>
                    </div>
                </div>
                <svg data-inview="fadeInUp" data-delay="600" class="arrow-down" xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="140 916.557 88.742 95.627"><path d="m228.742 967.813-4.973-4.973-35.828 35.83v-82.113h-7.012v82.24l-35.956-35.957-4.973 4.973 44.371 44.371 44.371-44.37Z" fill="#1e1e1e" fill-rule="evenodd" data-name="Path 30"/></svg>
            </div>
        </header>

        <p class="floater-left-rail" hero-transition-group>
            <span>{{ this.jobPost ? this.jobPost.title : '' }}</span>
        </p>

        <!-- Content -->
        <div
            id="zone-carrieres"
            class="carrieres carrieres-wrap no-border site-max-width"
            data-gsap-change-color="default"
            hero-transition-group
        >
            <div class="container" data-gsap-change-color="default">

                <!-- <div class="spacer"></div> -->

                <div class="carrieres-row details" data-gsap-change-color="default" v-if="pageIsLoaded && this.jobPost.poste">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Le poste</h3>
                    </div>
                    <div class="right details-desc" data-inview="fadeInUp" data-delay="400">
                        <div class="wysiwyg intro" v-html="this.jobPost.poste"></div>
                    </div>
                </div>

                <div class="carrieres-row details" data-gsap-change-color="default" v-if="pageIsLoaded">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Votre rôle</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <div class="wysiwyg role" v-html="this.jobPost.role"></div>
                    </div>
                </div>

                <div class="carrieres-row details no-border" data-gsap-change-color="default" v-if="pageIsLoaded">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Votre quotidien</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <div class="wysiwyg list" v-html="this.jobPost.quotidien"></div>
                    </div>
                </div>

                <div class="carrieres-row details no-border" data-gsap-change-color="default" v-if="pageIsLoaded">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Votre profil</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <div class="wysiwyg list" v-html="this.jobPost.profil"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carrieres ender -->
        <div class="carrieres-ender site-max-width">
            <div class="container">
                <div class="carrieres-row details ender" data-gsap-change-color="black">
                    <div class="left" data-inview="fadeInUp" data-delay="200">
                        <h3 class="title">Nos avantages</h3>
                    </div>
                    <div class="right" data-inview="fadeInUp" data-delay="400">
                        <div class="wysiwyg">
                            <ul>
                                <li>Accédez à un régime complet d’avantages sociaux compétitifs;</li>
                                <li>Procurez-vous, au besoin, une semaine de vacances supplémentaires chaque année;</li>
                                <li>Bénéficiez d’horaires flexibles;</li>
                                <li>Travaillez dans un lieu à proximité des transports en commun;</li>
                                <li>Œuvrez en mode télétravail;</li>
                                <li>Évoluez dans une entreprise qui se soucie de l’environnement;</li>
                                <li data-gsap-change-color="black">Participez à divers comités.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="cta">
                    <a :href="this.jobPost ? this.jobPost.lienPostulez : ''" class="link-text" target="_blank" rel="nooperner noreferrer">
                        <span>Postulez</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="124.43" height="124.43" viewBox="689.071 5131.187 124.43 124.43"><path d="M723.663 5161.133v6.712h48.361l-55.417 55.416 4.733 4.733 55.502-55.503v48.533h6.713v-59.891h-59.892Z" fill="#efeade" fill-rule="evenodd" data-name="Path 30"/></svg>
                    </a>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <router-link :to="{ name: 'HomePage-fr' }" class="full-video footer-nav" data-gsap-activate-footer data-gsap-change-color="black">
            <div class="container">
                <img class="main" src="@/assets/imgs/carrieres-footer.png" alt="Candidature spontanée" />
                <div class="content">
                    <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">
                        Préparez-vous à votre entrevue, apprenez-en plus sur le bureau créatif.

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="70"
                            viewBox="139 15409.606 95.627 88.742"
                        >
                            <path
                                d="m190.256 15409.607-4.973 4.973 35.829 35.828H139v7.012h82.239l-35.956 35.956 4.973 4.973 44.37-44.371-44.37-44.371Z"
                                fill="#ceffb4"
                                fill-rule="evenodd"
                                data-name="Path 471"
                            />
                        </svg>
                    </h2>
                    <!-- svg -->
                </div>
            </div>
        </router-link>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import GLightbox from 'glightbox'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
// gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Carrieres',

    components: {
        Footer,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.jobPost = this.globals.carrieresEntry.data.find(i => i.jsonUrl.includes(this.$route.params.slug))
                    document.querySelector('.main-nav').classList.remove('creativite', 'contenu', 'production')
                    document.querySelector('.main-nav').classList.add(this.jobPost.secteurSlug)

                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        setTimeout(() => {
                            this.initGsap()
                            this.initGsapBgColors()

                            this.gsapActivateFooter()

                            gsap.utils.toArray('[data-gsap-parrallax-scroll]').forEach(element => {
                                gsap.to(element, {
                                    scrollTrigger: {
                                        trigger: element,
                                        start: 'top bottom',
                                        end: 'bottom top',
                                        scrub: 1.475,
                                    },
                                    y: '-25%',
                                    ease: 'none',
                                })
                            })

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            jobPost: null
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGsap() {
            // gsap.utils.toArray('[data-gsap-scroll-left]').forEach(track => {
            //     gsap.to(track, {
            //         scrollTrigger: {
            //             trigger: track,
            //             start: 'top bottom',
            //             end: 'bottom top',
            //             scrub: 1.475,
            //         },
            //         x: '-25%',
            //         ease: 'none',
            //     })
            // })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 60%',
                    end: 'bottom 30%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-carrieres-details')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-carrieres-details').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-carrieres-details').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-carrieres-details')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-carrieres-details').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-carrieres-details').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-carrieres-details')) {
                        return;
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-carrieres-details')) {
                        return;
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        }
    },
}
</script>
